<template>
  <div>
    <div class="card card-custom gutter-b example example-compact">
      <div
        class="card-header d-flex align-items-center justify-content-between"
      >
        <div class="card-title">
          <h3 class="card-label">Shartnoma Maxsus Ilovasi</h3>
        </div>
        <div class="card-swithcer">
          <div>
            <b-form-checkbox
              :options="{ disabled: true }"
              switch
              v-model="checkedNDS"
              size="lg"
              >NDS</b-form-checkbox
            >
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="form-group">
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <h4>Mashina qo'shish</h4>
              </v-col>
              <v-col cols="6" class="border">
                <label>Oddiy</label>
                <v-autocomplete
                  small-chips
                  deletable-chips
                  outlined
                  hide-selected
                  :items="filteredTrucks"
                  item-text="model_name"
                  item-value="id"
                  dense
                  return-object
                  multiple
                  v-model="trucks"
                  :disabled="city != ''"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" class="border">
                <label>Ref</label>
                <v-autocomplete
                  small-chips
                  outlined
                  return-object
                  hide-selected
                  :items="filteredTrucks"
                  item-text="model_name"
                  item-value="id"
                  dense
                  multiple
                  v-model="trucksWithRef"
                  :disabled="city != ''"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div class="mt-4 mb-5">
          <div class="card-toolbar">
            <button
              @click="addTruck"
              to="/contractreg"
              :disabled="!!cities.length || (trucks == 0 && trucksWithRef == 0)"
              class="btn btn-success font-weight-bolder"
            >
              Ilovaga mashina qo'shish +
            </button>
          </div>
        </div>

        <div class="form-group">
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <h4>Yo'nalish qo'shish</h4>
              </v-col>
              <v-col cols="4" class="border">
                <label>Yo'nalish</label>
                <v-autocomplete
                  @input="setCities"
                  outlined
                  hide-selected
                  :items="AllCities"
                  item-text="name"
                  item-value="id"
                  dense
                  return-object
                  v-model="city"
                  :disabled="trucks != 0 || trucksWithRef != 0"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" class="border">
                <label>Shaharlar</label>
                <draggable
                  :move="checkMove"
                  :list="cities"
                  class="list-group"
                  group="people"
                >
                  <div
                    :class="
                      ++index == '1'
                        ? 'bg-white border my-1'
                        : 'bg-white border my-1'
                    "
                    class="list-group-item"
                    v-for="(element, index) in cities"
                    :key="element.name"
                  >
                    {{ element.name }}
                    <span
                      v-if="index == 1"
                      class="
                        f-right
                        label label-lg label-light-success label-inline
                      "
                      >Boshlangich manzil {{ (element.order = index) }}</span
                    >
                    <span
                      v-else
                      class="
                        f-right
                        label label-lg label-light-warning label-inline
                      "
                      >Manzil {{ (element.order = index) }}</span
                    >
                  </div>
                </draggable>
              </v-col>
              <v-col cols="4 " class="px-50 m-auto">
                <draggable group="people" :list="cities2" class="list-group">
                  <div class="col bg-light-danger px-6 py-8 rounded-xl mr-7">
                    <span
                      class="svg-icon svg-icon-danger svg-icon-2x d-block my-2"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24" />
                          <path
                            d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                          />
                          <path
                            d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                            fill="#000000"
                            opacity="0.3"
                          />
                        </g></svg
                    ></span>
                    <a
                      href="javascript:void(0)"
                      @click="cities = []"
                      class="text-danger font-weight-bold font-size-h6 mt-2"
                      >Shahar (O'chirish)</a
                    >
                  </div>
                </draggable>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div class="my-5">
          <div class="card-toolbar">
            <button
              @click="add"
              to="/contractreg"
              :disabled="cities.length < 1 || trucks != 0 || trucksWithRef != 0"
              class="btn btn-success font-weight-bolder"
            >
              Ilovaga yo'nalish qo'shish +
            </button>
          </div>
        </div>
        <template v-if="isLoading">
          <div class="p-4 text-center">
            <b-spinner
              style="width: 3rem; height: 3rem"
              label="Large Spinner"
            />
          </div>
        </template>
        <div class="zebra__wrapper" v-else>
          <table class="zebra">
            <thead>
              <tr>
                <th style="text-align: center; font-weight: normal" rowspan="2">
                  Manzil
                </th>
                <th
                  class="table__cell"
                  colspan="3"
                  v-for="truck in AllTrackTypes"
                  :key="truck.id"
                >
                  {{
                    truckModel(truck, getCurrContractDetails)
                      .trucktype_description.brand.name
                  }}
                  {{
                    truckModel(truck, getCurrContractDetails)
                      .trucktype_description.model
                  }}
                  <div>
                    (yuk ogirligi:
                    {{
                      truckModel(truck, getCurrContractDetails)
                        .trucktype_description.capacity_kg
                    }}
                    kg <br />
                    yuk sig'imi:
                    {{
                      truckModel(truck, getCurrContractDetails)
                        .trucktype_description.capacity_m3
                    }}
                    m3)
                    <span
                      class="label label-lg label-light-warning label-inline"
                      v-if="truckModel(truck, getCurrContractDetails).is_ref"
                    >
                      Ref
                    </span>
                  </div>
                </th>
              </tr>
              <tr>
                <th
                  class="table__cell"
                  v-for="i in AllTrackTypes.length * 3"
                  :key="i"
                >
                  <strong> {{ i | headTable }}</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in marshrut" :key="index">
                <td
                  style="border-left: 1px solid #000; position: sticky; left: 0"
                >
                  <span
                    v-for="(city, cityItemIndex) in item.cities"
                    :key="cityItemIndex"
                  >
                    {{ city.name }}
                  </span>
                </td>
                <td
                  class="table__cell"
                  v-for="(
                    truck, allTruckTypesItemIndex
                  ) in AllTrackTypes.length * 3"
                  :key="allTruckTypesItemIndex"
                >
                  <input
                    @change="
                      updatePrice(
                        item.direction_truck[ad(allTruckTypesItemIndex)]
                      )
                    "
                    :disabled="[0, 1].includes(allTruckTypesItemIndex % 3)"
                    @input="
                      cal(
                        item.direction_truck,
                        checkType(allTruckTypesItemIndex),
                        allTruckTypesItemIndex,
                        index
                      )
                    "
                    v-model="
                      item.direction_truck[ad(allTruckTypesItemIndex)][
                        checkType(allTruckTypesItemIndex)
                      ]
                    "
                    v-currency="currencyOptions"
                    type="text"
                  />
                  <!-- <input
                    v-else
                    :value="
                      cal(item.direction_truck[ad(index)], checkType(index))
                    "
                    type="text"
                    disabled
                  /> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="my-5" v-if="!isLoading">
          <button
            @click="send"
            :disabled="marshrut.length == 0"
            class="btn btn-primary w-100"
          >
            Yuborish
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import draggable from 'vuedraggable'
import { numberWithSpaces, currencyOptions } from '@/utils'
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'

export default {
  display: 'Transition',
  components: { draggable },
  data() {
    return {
      city: '',
      checkedNDS: false,
      cities: [],
      cities2: [{ icon: 'name' }],
      marshrut: [],
      selectedCurrencyOption: 1,
      currencyCode: 'USD',
      distractionFree: false,
      hideCurrencySymbol: true,
      hideGroupingSymbol: true,
      hideNegligibleDecimalDigits: true,
      prefix: null,
      suffix: null,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 0,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999],
      id: '',
      status: false,
      isLoading: false,

      trucks: [],
      trucksWithRef: []
    }
  },
  watch: {
    cities2() {
      this.city = ''
    },
    checkedNDS() {
      this.calc(this.marshrut)
    },
    getCurrContractDetails(val) {
      if (val) {
        val.marshrut_description.forEach((item) => {
          this.id = val.id
          const data = {
            cities: [],
            direction_truck: [
              ...item.prices.map((item) => {
                const data = {
                  truck_type: item.truck_type,
                  status: true,
                  id: item.id,
                  is_ref: item.is_ref,
                  nds_amout: item.nds_amout,
                  price: item.price,
                  priceWithNds: item.price_with_nds
                }
                this.checkedNDS = item.nds
                return data
              })
            ]
          }
          item.cities.forEach((x) => {
            const data2 = {
              id: x.city,
              name: x.city_name.name,
              order: x.order
            }
            data.cities.push(data2)
          })
          this.marshrut.push(data)
        })
      }
    }
  },
  computed: {
    currencyOptions,

    AllTrackTypes2() {
      const data = this.$store.state.requests.allTruckTypes
      data.forEach((x) => {
        x.model_name =
          (x.brand && x.brand.name) +
          ' ' +
          x.model +
          ' (' +
          x.capacity_kg +
          ' kg, ' +
          x.capacity_m3 +
          ' m3' +
          ')'
      })
      return this.$store.state.requests.allTruckTypes
    },

    filteredTrucks() {
      // const selectTruckIdList = this.AllTrackTypes2.map(item => item.id)

      const tableTrucksList = this.AllTrackTypes.map((truck) => {
        return this.truckModel(truck, this.getCurrContractDetails)
          .trucktype_description.id
      })

      // console.log('selectTruckIdList', selectTruckIdList)
      // console.log('tableTrucksList', tableTrucksList)

      return this.AllTrackTypes2.filter(
        (item) => !tableTrucksList.includes(item.id)
      )
    },

    getCurrContractDetails() {
      let data = this.$store.state.requests.newMarshrut[0]
      if (!data) data = {}
      return data
    },
    // options() {
    //   return {
    //     locale: 'en-UK',
    //     currency: [null],
    //     // valueRange: this.valueRangeEnabled
    //     //   ? { min: this.valueRange[0], max: this.valueRange[1] }
    //     //   : undefined,
    //     precision: { min: 0 },
    //     distractionFree: this.distractionFree,
    //     autoDecimalMode: true
    //     // valueAsInteger: false,
    //     // allowNegative: false
    //   }
    // },
    nds() {
      var data1
      if (this.checkedNDS) {
        data1 = this.$store.state.requests.nds.nds
      } else {
        data1 = 0
      }
      return data1
    },
    AllCities() {
      let data = this.$store.state.requests.Cities
      if (this.city.id) data = data.filter((x) => x.id !== this.city.id)
      return data
    },
    AllTrackTypes() {
      let data = []
      let myList = []
      if (
        this.getCurrContractDetails &&
        this.getCurrContractDetails.marshrut_description
      ) {
        myList = new Set()
        data = this.getCurrContractDetails.marshrut_description
        data[data.length - 1].prices.forEach((price) => {
          const data = {
            truck_type: price.truck_type,
            is_ref: price.is_ref
          }
          myList.add(data)
        })
        myList = Array.from(myList)
      }
      return myList
    }
  },
  filters: {
    headTable(index) {
      if (index % 3 == 1) {
        return 'Summa'
      } else if (index % 3 == 2) {
        return 'Nds'
      } else if (index % 3 == 0) {
        return 'Summa(nds)'
      }
    },

    numberWithSpaces
  },
  methods: {
    // onDirectionTruckChanged(fn, { val, allTruckTypesItemIndex, index }) {
    //   if (typeof fn === 'function') {
    //     fn.apply(this)
    //   }
    //   // console.log('val', val)
    //   // console.log('checkType', this.checkType(allTruckTypesItemIndex))
    //   this.marshrut[index].direction_truck[this.ad(allTruckTypesItemIndex)][
    //     this.checkType(allTruckTypesItemIndex)
    //   ] = +(val || '').toString().replace(/,/g, '')

    //   // debugger
    // },

    ...mapActions(['addTruckToContractAppendixById']),

    truckModel(set, trucks) {
      let data
      if (Boolean(trucks) && Boolean(trucks.marshrut_description))
        trucks.marshrut_description.forEach((truck) => {
          data = truck.prices.find(
            (x) => x.truck_type == set.truck_type && x.is_ref == set.is_ref
          )
        })
      return data
    },
    toFloat(val) {
      if (typeof val == 'string' && Boolean(val))
        return parseFloat(val.replace(/[ ,]/g, ''))
      else if (typeof val == 'number') return val
      else {
        return 0
      }
    },
    checkMove(evt) {
      return evt.draggedContext.element.name !== 'apple'
    },
    setCities() {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(this.city)) {
        this.cities.push(this.city)
        this.trucks = []
        this.trucksWithRef = []
      }
    },
    deleteRow(value) {
      this.marshrut.splice(value, 1)
    },
    ad(index) {
      return Math.floor(index / 3)
    },
    checkType(index) {
      if (index % 3 == 0) return 'price'
      else if (index % 3 == 1) return 'nds_amout'
      else if (index % 3 == 2) return 'priceWithNds'
    },
    calc(val) {
      if (this.status) {
        // console.log('calc init')
        val.forEach((x, marshrutIndex) => {
          x.direction_truck.forEach((element, dtIndex) => {
            // eslint-disable-next-line no-extra-boolean-cast
            if (
              Boolean(
                this.marshrut[marshrutIndex].direction_truck[dtIndex].price
              ) &&
              this.marshrut[marshrutIndex].direction_truck[dtIndex].price !== ''
            ) {
              if (this.checkedNDS) {
                const priceExceptNDS = Math.round(
                  this.toFloat(element.priceWithNds) / (1 + this.nds / 100)
                )
                // console.log(
                //   'f',
                //   `${this.toFloat(element.priceWithNds)} / (1 + ${
                //     this.nds
                //   } / 100)`
                // )

                // console.log('priceExceptNDS', priceExceptNDS)

                if (!isNaN(priceExceptNDS)) {
                  this.marshrut[marshrutIndex].direction_truck[dtIndex].price =
                    priceExceptNDS

                  this.marshrut[marshrutIndex].direction_truck[
                    dtIndex
                  ].nds_amout = (this.toFloat(element.price) * this.nds) / 100
                }
              } else {
                this.marshrut[marshrutIndex].direction_truck[
                  dtIndex
                ].nds_amout = '0'

                this.marshrut[marshrutIndex].direction_truck[dtIndex].price =
                  element.priceWithNds
              }
              this.updatePrice(
                this.marshrut[marshrutIndex].direction_truck[dtIndex]
              )
            } else
              this.marshrut[marshrutIndex].direction_truck[
                dtIndex
              ].priceWithNds = '0'
          })
        })
      }
    },
    // cal(val, type, index) {
    //   if (this.status) {
    //     if (type == 'price') {
    //       val.priceWithNds =
    //         (this.toFloat(val.price) * this.nds) / 100 + this.toFloat(val.price)
    //       val.priceWithNds = val.priceWithNds.toLocaleString('zh-ZH')
    //     } else if (type !== 'price' && type !== 'priceWithNds') {
    //       return ((this.toFloat(val.price) * this.nds) / 100).toLocaleString(
    //         'zh-ZH'
    //       )
    //     } else if (type == 'priceWithNds') {
    //       if (!val.priceWithNds) val.priceWithNds = 0
    //       return val.priceWithNds.toLocaleString('zh-ZH')
    //     }
    //   } else {
    //     console.log('val', val)
    //     if (type == 'price') {
    //       return val.price
    //     } else if (type !== 'price' && type !== 'priceWithNds') {
    //       return ((this.toFloat(val.price) * this.nds) / 100).toLocaleString(
    //         'zh-ZH'
    //       )
    //     } else if (type == 'priceWithNds') {
    //       if (!val.priceWithNds) val.priceWithNds = 0
    //       return val.priceWithNds.toLocaleString('zh-ZH')
    //     }
    //   }
    // },
    cal(val, type, index, marshrut_item_index) {
      let i = Math.floor(index / 3)
      if (!isNaN(i)) {
        let data = val.find(
          (x) =>
            x.truck_type == this.AllTrackTypes[i].truck_type &&
            x.is_ref == this.AllTrackTypes[i].is_ref
        )
        let dataIndex = val.findIndex(
          (x) =>
            x.truck_type == this.AllTrackTypes[i].truck_type &&
            x.is_ref == this.AllTrackTypes[i].is_ref
        )

        if (dataIndex != -1) {
          // console.log(
          //   'dataIndex',
          //   this.marshrut[marshrut_item_index].direction_truck[dataIndex]
          // )

          if (this.status && data) {
            // console.log('val', val)

            if (type == 'price') {
              this.marshrut[marshrut_item_index].direction_truck[
                dataIndex
              ].priceWithNds =
                (this.toFloat(
                  this.marshrut[marshrut_item_index].direction_truck[dataIndex]
                    .price
                ) *
                  this.nds) /
                  100 +
                this.toFloat(
                  this.marshrut[marshrut_item_index].direction_truck[dataIndex]
                    .price
                )
              this.marshrut[marshrut_item_index].direction_truck[
                dataIndex
              ].priceWithNds = Math.floor(
                this.marshrut[marshrut_item_index].direction_truck[dataIndex]
                  .priceWithNds
              ).toLocaleString('zh-ZH')
              this.marshrut[marshrut_item_index].direction_truck[
                dataIndex
              ].nds_amout =
                (this.toFloat(
                  this.marshrut[marshrut_item_index].direction_truck[dataIndex]
                    .price
                ) *
                  this.nds) /
                100
              this.marshrut[marshrut_item_index].direction_truck[
                dataIndex
              ].nds_amout =
                this.marshrut[marshrut_item_index].direction_truck[
                  dataIndex
                ].nds_amout.toLocaleString('zh-ZH')
            } else if (type !== 'price' && type !== 'priceWithNds') {
              return (this.marshrut[marshrut_item_index].direction_truck[
                dataIndex
              ].nds_amout =
                (this.toFloat(
                  this.marshrut[marshrut_item_index].direction_truck[dataIndex]
                    .price
                ) *
                  this.nds) /
                100)
            } else if (type == 'priceWithNds') {
              const newPriceWithNdsVal = this.toFloat(
                this.marshrut[marshrut_item_index].direction_truck[dataIndex]
                  .priceWithNds
              )

              // console.log(
              //   'priceWithNds',
              //   newPriceWithNdsVal.toLocaleString('zh-ZH')
              // )

              if (!isNaN(newPriceWithNdsVal)) {
                this.marshrut[marshrut_item_index].direction_truck[
                  dataIndex
                ].priceWithNds = newPriceWithNdsVal.toLocaleString('zh-ZH')
              }

              if (!this.checkedNDS) {
                const priceWithNds = this.toFloat(
                  this.marshrut[marshrut_item_index].direction_truck[dataIndex]
                    .priceWithNds
                )

                if (!isNaN(priceWithNds)) {
                  this.marshrut[marshrut_item_index].direction_truck[
                    dataIndex
                  ].price = priceWithNds.toLocaleString('zh-ZH')
                }
              }
              // if (
              //   !this.marshrut[marshrut_item_index].direction_truck[dataIndex]
              //     .priceWithNds
              // )
              //   this.marshrut[marshrut_item_index].direction_truck[
              //     dataIndex
              //   ].priceWithNds = 0
              else if (this.nds) {
                this.marshrut[marshrut_item_index].direction_truck[
                  dataIndex
                ].nds_amout =
                  this.toFloat(this.nds / (100 + this.nds)) *
                  this.toFloat(
                    this.marshrut[marshrut_item_index].direction_truck[
                      dataIndex
                    ].priceWithNds
                  )
                this.marshrut[marshrut_item_index].direction_truck[
                  dataIndex
                ].nds_amout = Math.round(
                  this.marshrut[marshrut_item_index].direction_truck[dataIndex]
                    .nds_amout
                ).toLocaleString('zh-ZH')
                // console.log(
                //   this.marshrut[marshrut_item_index].direction_truck[dataIndex]
                //     .nds_amout
                // )
                this.marshrut[marshrut_item_index].direction_truck[
                  dataIndex
                ].price =
                  this.toFloat(
                    this.marshrut[marshrut_item_index].direction_truck[
                      dataIndex
                    ].priceWithNds
                  ) -
                  this.toFloat(
                    this.marshrut[marshrut_item_index].direction_truck[
                      dataIndex
                    ].nds_amout
                  )
                this.marshrut[marshrut_item_index].direction_truck[
                  dataIndex
                ].price =
                  this.marshrut[marshrut_item_index].direction_truck[
                    dataIndex
                  ].price.toLocaleString('zh-ZH')
                return this.marshrut[marshrut_item_index].direction_truck[
                  dataIndex
                ].price
              }
              return this.marshrut[marshrut_item_index].direction_truck[
                dataIndex
              ].priceWithNds.toLocaleString('zh-ZH')
            }
          } else {
            if (type == 'price') {
              return this.marshrut[marshrut_item_index].direction_truck[
                dataIndex
              ].price
            } else if (type !== 'price' && type !== 'priceWithNds') {
              return (
                (this.toFloat(
                  this.marshrut[marshrut_item_index].direction_truck[dataIndex]
                    .price
                ) *
                  this.nds) /
                100
              ).toLocaleString('zh-ZH')
            } else if (type == 'priceWithNds') {
              if (
                !this.marshrut[marshrut_item_index].direction_truck[dataIndex]
                  .priceWithNds
              )
                this.marshrut[marshrut_item_index].direction_truck[
                  dataIndex
                ].priceWithNds = 0
              return this.marshrut[marshrut_item_index].direction_truck[
                dataIndex
              ].priceWithNds.toLocaleString('zh-ZH')
            }
          }
        }
      }
    },
    add() {
      const data = {
        cities: [],
        direction_truck: [
          ...this.AllTrackTypes.map((x) => {
            const data = {
              truck_type: x.truck_type,
              status: false,
              id: x.truck_type,
              is_ref: x.is_ref,
              nds_amout: 0,
              price: 0,
              priceWithNds: 0
            }
            return data
          })
        ]
      }
      // eslint-disable-next-line no-unused-vars
      this.cities.forEach((x, index) => {
        const data2 = {
          id: x.id,
          name: x.name,
          order: index + 1 // x.order
        }
        data.cities.push(data2)
      })
      this.marshrut.push(data)
      this.cities = []
    },
    addTruck() {
      // todo
      const new_truck_types = [
        ...this.trucks.map((item) => ({
          truck_type_id: item.id,
          is_ref: false
        })),
        ...this.trucksWithRef.map((item) => ({
          truck_type_id: item.id,
          is_ref: true
        }))
      ]

      // console.log('new_truck_types', new_truck_types)
      if (!new_truck_types.length) {
        console.error("Can't append truck, new_truck_types not filled")
        return
      }

      this.addTruckToContractAppendixById({
        contractId: this.id,
        new_truck_types
      }).then(() => {
        Swal.fire({
          title: "Mashina(lar) qo'shildi",
          text: "Ma'lumotlar yuborildi!",
          icon: 'success'
        }).then((result) => {
          if (result.isConfirmed) {
            this.trucks = []
            this.trucksWithRef = []
            this.$router.go()
          }
        })
      })
    },

    updatePrice(val) {
      if (val.status) {
        const valClone = JSON.parse(JSON.stringify(val))
        const data = {
          price: this.toFloat(valClone.price),
          nds_amout: (this.toFloat(valClone.price) * this.nds) / 100,
          price_with_nds: this.toFloat(valClone.priceWithNds),
          nds: this.checkedNDS
        }
        // console.log('updatePrice', data)
        this.$store.dispatch('updateClientContAppen', {
          data,
          id: val.id
        })
      }
    },
    send() {
      const data = {
        appendix: this.id,
        directions: []
      }
      this.marshrut = this.marshrut.filter((x) =>
        x.direction_truck.find((x) => !x.status)
      )
      this.marshrut.forEach((x) => {
        const data2 = {
          cities: [
            ...x.cities.map((x) => {
              const data = {
                city: x.id,
                order: x.order
              }
              return data
            })
          ],
          price: [
            ...x.direction_truck.map((x) => {
              const data = {
                truck_type: x.id,
                // eslint-disable-next-line no-extra-boolean-cast
                price: Boolean(x.price) ? this.toFloat(x.price) : 0,
                nds: Boolean(this.nds),
                // eslint-disable-next-line no-extra-boolean-cast
                nds_amout: Boolean(this.nds)
                  ? (this.toFloat(x.price) * this.nds) / 100
                  : 0,
                is_ref: x.is_ref,
                // eslint-disable-next-line no-extra-boolean-cast
                price_with_nds: Boolean(x.priceWithNds)
                  ? this.toFloat(x.priceWithNds)
                  : 0
              }
              return data
            })
          ]
        }
        data.directions.push(data2)
      })
      this.$store.dispatch('updateClientContAppenDirection', data)
    },

    async fetchData() {
      this.isLoading = true

      try {
        await this.$store.dispatch('getClientContract', this.$route.params.id)
        await this.$store.dispatch('getAllCities')
        await this.$store.dispatch('getAllTruckTypes')
        await this.$store.dispatch('getNds')
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
        //
      }
    }
  },

  mounted() {
    setTimeout(() => {
      this.status = true
    }, 3000)
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Shartnoma' },
      { title: 'Shartnoma maxsus ilovasi' }
    ])

    this.fetchData()
  }
}
</script>
<style scoped>
.zebra thead tr:first-child th:first-child {
  border-left: 1px solid #000;
  border-bottom: 1px solid #333;
}
.zebra thead tr:first-child th {
  position: sticky;
  top: 0;

  background-color: #fff;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
}
.zebra thead tr:first-child th:first-child {
  position: sticky;
  left: 0;
  z-index: 1;

  background-color: #fff;
}
.zebra thead tr:nth-child(2) th {
  position: sticky;
  top: 70px;

  background-color: #fff;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.zebra tbody tr:first-child td:first-child {
  position: sticky;
  left: 0;
}

.zebra tbody tr td {
  background-color: rgb(255, 255, 255);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.zebra thead {
  z-index: -9;
}
.zebra__wrapper {
  /* height: 450px; */
  overflow-x: scroll;
}
.active {
  background-color: pink;
}
</style>
<style>
.multi-select-wrapper {
  display: block;
  font-size: 16px;
}
.search-wrapper div ul li {
  flex: unset;
}
.search-input {
  color: black;
}
.pill {
  padding: 0.5em;
}
</style>
<style lang="scss" scoped>
.v-application.v-application--is-ltr.theme--light
  ::v-deep
  .menuable__content__active {
  height: 160px !important;
}
</style>
<style scoped>
.removeCell {
  cursor: pointer;
  transition: all 0.2s;
}
/* th {
  padding: .rem !important;
} */
.removeCell:hover {
  background-color: rgb(250, 228, 228);
}
.card-wrapper {
  padding-top: 40px;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.card-wrapper__item {
  flex: 0 0 auto !important;
  margin-right: 10px;
}
.zebra__wrapper {
  overflow-x: scroll;
  white-space: nowrap;
  margin: 1rem 0;
}
.zebra {
  border-collapse: separate;
  border-spacing: 0;
}

/* .td__cell {
  padding: 1.25rem;
} */
.table__cell {
  text-align: center;
  font-weight: 400;
  padding: 0.5rem;
}
.td_cell_brand {
  padding: 1.25rem 2rem;
  padding-left: 1.25rem;
}
#chipcustomization {
  width: 100%;
}
#chipcustomization .chipcontent {
  margin: 0 auto;
  width: 100%;
}
.multiselect-chip-custom .e-multi-select-wrapper .e-chips {
  opacity: 0.9;
}

.multiselect-chip-custom .e-multi-select-wrapper .e-chips:hover {
  opacity: 1;
}
.px-50 {
  padding: 0px 50px;
}
.multiselect-chip-custom
  .e-multi-select-wrapper
  .e-chips
  .e-chips-close.e-icon::before,
.multiselect-chip-custom .e-multi-select-wrapper .e-chips .e-chipcontent,
.multiselect-chip-custom .e-multi-select-wrapper .e-chips .e-chipcontent:hover {
  color: #ffffff;
}
.e-multi-select-wrapper .e-chips {
  background-color: #ff843d;
}
.e-multi-select-wrapper .e-chips:hover {
  background-color: #f5762d;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.td__details th {
  width: 1%;
}
th,
td {
  padding: 0.25rem;
  text-align: left;
}
tbody tr:nth-child(odd) {
  background: #eee;
}
tbody tr td input {
  text-align: center;
}
.f-right {
  float: right;
}
.btncard {
  display: inline-block;
  color: #464e5f;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.42rem;
  -webkit-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
    -webkit-box-shadow 0.3s ease-in-out;
}
.btncard.btn-primarycard {
  background-color: #c0dfff;
  border-color: #c0dfff;
}
.btncard.btn-primarycard:hover {
  background-color: #a2d1ff !important;
}
.list-group-item {
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}
.list-group-item:hover {
  transition: all 0.3s;
  background-color: rgb(242, 242, 242) !important;
}
</style>
